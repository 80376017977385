





























































































































































import Vue from "vue";
import { Getter, Action } from "vuex-class";
import { validationMixin } from "vuelidate";
import { helpers } from "vuelidate/lib/validators";
import { Component } from "vue-property-decorator";
import { email, sameAs, required, minLength } from "vuelidate/lib/validators";
import api from "@/core/utils/api";

type InvInfo = {
  companyName: string;
  seminarName: string;
  retrieved?: boolean;
};

const passwordRules = helpers.regex(
  "passwordRules",
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/,
);

@Component({
  mixins: [validationMixin],
  validations: {
    emailInput: { required, email },
    firstNameInput: { required, minLength: minLength(2) },
    lastNameInput: { required, minLength: minLength(2) },
    passwordInput: {
      required,
      minLength: minLength(8),
      passwordRules,
    },
    confirmPasswordInput: {
      required,
      sameAsPassword: sameAs("passwordInput"),
    },
    tosAgreement: {
      sameAs: sameAs(() => true),
    },
  },
})
export default class Register extends Vue {
  @Getter("profile/loading") loading!: boolean;
  @Action("displaySnackbar") displaySnackbar!: (msg: string) => void;
  @Action("clearEverything") clearEverything!: (reroute: boolean) => void;
  @Action("profile/registerUser") registerUser!: (data: any) => Promise<void>;

  step = 1;
  checkingEmail = false;
  showPass = false;
  emailInput = "";
  firstNameInput = "";
  lastNameInput = "";
  passwordInput = "";
  confirmPasswordInput = "";
  tosAgreement = false;

  // query
  emailDisabled = false;
  invitationToken: string | null = null;

  lastCheckedEmail = "";

  async checkEmailExists() {
    const email = this.emailInput;
    if (this.lastCheckedEmail === email) return;

    this.checkingEmail = true;
    const exists = await api.get("/api/Account/CheckIfEmailExists/" + email);
    this.checkingEmail = false;
    this.lastCheckedEmail = email;
    return exists;
  }

  async next() {
    // email input
    this.$v.$touch();
    if (this.step === 1)
      if (this.emailErrors.length > 0)
        this.displaySnackbar(
          this.$t("registerSection.invalidEmail").toString(),
        );
      else if (await this.checkEmailExists())
        this.displaySnackbar(
          this.$t("registerSection.emailAlreadyTaken").toString(),
        );
      else {
        this.step = 2;
        this.$v.$reset();
      }
    // name input
    else if (this.step === 2)
      if (this.firstNameErrors.length > 0 || this.lastNameErrors.length > 0)
        this.displaySnackbar(this.$t("registerSection.flName").toString());
      else {
        this.step = 3;
        this.$v.$reset();
      }
    // passwords input + submit
    else if (await this.submit()) this.$v.$reset();
  }

  get emailErrors() {
    const errors: any[] = [];
    if (!this.$v.emailInput!.$dirty) return errors;
    !this.$v.emailInput!.required &&
      errors.push(this.$t("registerSection.email"));
    !this.$v.emailInput!.email &&
      errors.push(this.$t("registerSection.invalidEmail"));
    return errors;
  }

  get firstNameErrors() {
    const errors: any[] = [];
    if (!this.$v.firstNameInput!.$dirty) return errors;
    !this.$v.firstNameInput!.required &&
      errors.push(this.$t("registerSection.fNameRequired"));
    !this.$v.firstNameInput!.minLength &&
      errors.push(this.$t("registerSection.fNameMin"));
    return errors;
  }

  get lastNameErrors() {
    const errors: any[] = [];
    if (!this.$v.lastNameInput!.$dirty) return errors;
    !this.$v.lastNameInput!.required &&
      errors.push(this.$t("registerSection.lNameRequired"));
    !this.$v.lastNameInput!.minLength &&
      errors.push(this.$t("registerSection.lNameMin"));
    return errors;
  }

  get passwordErrors() {
    const errors: any[] = [];
    if (!this.$v.passwordInput!.$dirty) return errors;
    if (!this.$v.passwordInput!.required)
      errors.push(this.$t("registerSection.password"));
    if (!this.$v.passwordInput!.minLength)
      errors.push(this.$t("registerSection.passMin"));
    if (!this.$v.passwordInput!.passwordRules)
      errors.push(this.$t("registerSection.passRules"));
    return errors;
  }

  get confirmPasswordErrors() {
    const errors: any[] = [];
    if (!this.$v.confirmPasswordInput!.$dirty) return errors;
    !this.$v.confirmPasswordInput!.required &&
      errors.push(this.$t("registerSection.confirmPass"));
    !this.$v.confirmPasswordInput!.sameAsPassword &&
      errors.push(this.$t("registerSection.passMismatch"));
    return errors;
  }

  get agreementErrors() {
    const errors: any[] = [];
    if (!this.$v.confirmPasswordInput!.$dirty) return errors;
    !this.$v.tosAgreement!.sameAs &&
      errors.push(this.$t("registerSection.tOS"));
    return errors;
  }

  async submit(): Promise<boolean> {
    let res: boolean = false;
    console.log("submitting");

    this.$v.$touch();
    if (!this.$v.$invalid) {
      console.log("valid form");
      this.clearEverything(false);
      try {
        await this.registerUser({
          email: this.emailInput.toLowerCase(),
          password: this.passwordInput,
          firstName: this.firstNameInput,
          lastName: this.lastNameInput,
          invitationToken: this.invitationToken,
        });
        console.log("user registered");
        res = true;
      } catch {
        this.displaySnackbar(
          this.$t("registerSection.registerFailed").toString(),
        );
      }
    } else
      this.displaySnackbar(
        this.$t("registerSection.validationError").toString(),
      );

    console.log("submitted");
    return res;
  }

  beforeDestroy() {
    this.emailInput = "";
    this.passwordInput = "";
    this.confirmPasswordInput = "";
  }

  getting = false;
  invitation: InvInfo = {
    companyName: "",
    seminarName: "",
  };

  async getInfo() {
    this.getting = true;
    try {
      const end = `/api/Invitations/${this.emailInput}/${this.invitationToken}`;
      this.invitation = await api.get(end);
      this.invitation.retrieved = false;
    } catch (error) {
      console.log(error);
    }
    this.getting = false;
  }

  mounted() {
    const { query } = this.$route;
    if (
      typeof query.email === "string" &&
      typeof query.invitationToken === "string"
    ) {
      this.invitationToken = query.invitationToken;
      this.emailInput = query.email;
      this.emailDisabled = true;
      this.getInfo();
    }
  }
}
